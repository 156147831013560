// Step 1: Import React
import * as React from 'react'
import Layout from '../components/layout'
import {StaticImage} from 'gatsby-plugin-image'


import ContactIntro from '../components/contact/contact-intro'

// Step 2: Define your component
const ContactPage = () => {
  // console.log(data)
  return (
    <Layout pageTitle="Contact Page">
      <ContactIntro/>

      <div className="contact-container">
        <div className="contact-left">
          {/* <div className="contact__img"> */}
              <StaticImage alt={`alt img`} className="contact__img" src='../images/Group57@2x.png'/>
              <StaticImage alt={`contact img`} className="contact__img" src='../images/Group56@2x.png'/>
          {/* </div> */}
        </div>
        <div className="contact-right">

                  <form action="#" className="form">
                    <span className="form__field form__name-field">
                      <label htmlFor="name" className='form__heading'>How may I address you? </label>
                      <input type="text" className='form__input' placeholder="e.g: Lorem Ipsum, Mr. Ipsum" id="name" name="name"/>
                      
                    </span>
                    <span className="form__heading">How may I reach out to you?</span>
                    <span className="form__field form__email-field">
                      <label htmlFor="email" className='form__heading-sm'>Email </label>
                      <input type="email" className='form__input' placeholder="e.g: xxxxx@gmail.com" id="email" name="email"/>

                    </span>
                    <span className="form__field form__phone-field">
                      <label htmlFor="phone" className='form__heading-sm'>Phone </label>
                      <input type="phone" className='form__input' placeholder="e.g: +81 234 2345 2124" id="phone" name="phone"/>

                    </span>
                    {/* <span className="form__heading">How may I help you?</span>     */}
                    <span className="form__field form__message-field">
                      <label htmlFor="feedback" className='form__heading'>How may I help you?</label>
                      <textarea id="feedback" className='form__input form__txt-area' placeholder="Something you want to ask or tell us" name="feedback"></textarea>
                      
                    </span>

                    {/* <div className="check-box">
                        <input type="checkbox" id="remember" name="remember"/>
                        <label htmlFor="remember">Remember Me</label>
                    </div> */}
                    <span className="form__submit">
                      <input type="submit" className='btn btn-pink form__submit-btn' value="Submit"/>
                    </span>
                  </form>
        </div>
      </div>
    </Layout>
   
  )
}
// Step 3: Export your component
export default ContactPage