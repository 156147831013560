// Step 1: Import React
import * as React from 'react'
// import Layout from '../components/layout'
import {StaticImage} from 'gatsby-plugin-image'
import { Link } from 'gatsby'



// Step 2: Define your component
const ContactIntro = () => {
  // console.log(data)
  return (
    
    <section className="contact-intro">
        <div className="contact-intro__coverimg">
            <StaticImage alt={`cover img`} className="coverimg" src='../../images/annie-spratt-ujM3G50LggQ-unsplash.jpg'/>
            <div className="contact-intro__bgoverlay"></div>
        </div>
        <div className="contact-intro__text">
            <p className="paragraph-sm">Contact Me!</p>
            <p className="heading-lg">Let's create content that your learners will<span className="pink-text"> love</span></p>
            <hr className="lbr" />
            <div className="btn-group">
                <Link to='/portfolio' state={{category: "default"}} className="btn btn-pink">See My Works</Link>
            </div>
        
        </div>
    </section>
   

   
  )
}
// Step 3: Export your component
export default ContactIntro