import * as React from 'react'
import { useEffect, useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import {StaticImage} from 'gatsby-plugin-image'
import '../scss/main.scss'
import Logo from '../assets/Logo.svg'
import Fb from '../assets/facebook.svg'
import Gm from '../assets/google-plus.svg'
import Li from '../assets/linkedin2.svg'



const Layout = ({ pageTitle, children }) => {
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.onscroll = () => {
        setOffset(window.scrollY)
      }
    }
  }, [])

  const data = useStaticQuery(graphql`
    query {
        allWpCategory {
            edges {
              node {
                id
                slug
              }
            }
          }
    }
  `)
  // const data = useStaticQuery(graphql`
  //   query {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)
  // console.log(data)
  // console.log(offset)

  return (
    <div className="container">
        <title>{pageTitle}</title>


        <header className={`${offset > 100 ? 'header header__active' : 'header'}`}> 
          <Link to="/" className="header__logo-link">
            {/* <div className="header__logo">
              <StaticImage alt='Logo' src='../images/Asset10.svg' /> 
            </div> */}
            <Logo className='header__logo'/>
            <div className="header__logo-text"><span className="bold">Lili</span>an's</div>
          </Link>
          

          <nav className="navigation">
            <Link to="/" activeStyle={{ fontWeight: "600", color: "#fd8483"}} className="navigation__item">home</Link>
            <Link to="/portfolio" state={{category: "default"}} activeStyle={{ fontWeight: "600", color: "#fd8483"}} className="navigation__item">portfolio</Link>
            <Link to="/about" activeStyle={{ fontWeight: "600", color: "#fd8483"}} className="navigation__item">about</Link>
            <Link to="/contact" activeStyle={{ fontWeight: "600", color: "#fd8483"}} className="navigation__item">contact</Link>
          </nav>

        </header>

        <main className="content">
            {children}
        </main>

        <footer className="footer"> 
          <div className="footer__content">
            <div className="footer__content-upper">

              <div className="footer__contact">
                <div className="heading-md">Get In Touch</div>
                <div className="footer__contact-info">
                  <div className="footer__email">Email: elearninglilian@gmail.com</div>
                  <div className="footer__Tel">Tel: +84 962 011 565</div>
                </div>
                
              </div>
              <div className="footer__portfolio">
                <div className="heading-md">Portfolio</div>
                <div className="footer__portfolio-info">
                  {
                    data.allWpCategory.edges.filter(edge => edge.node.slug !== "default").map(edge => {
                        return(
                            <Link to="/portfolio" key={edge.node.id} state={{category: edge.node.slug}} className="footer__portfolio-item">{edge.node.slug.replace(/-/g, " ")}</Link>
                        )
                    })
                  }
                </div>
                
              </div>
            </div>

            <div className="footer__content-lower">
              <div className="copyright">@ 2022 Lilian Le | eLearning Designer and Developer Based out of Hanoi, Viet Nam. All rights reserved. <span className='white-text'>Terms | Privacy.</span></div>
            </div>
          </div>

          <div className='footer__graphic'>
            <Link to='/'>
              <div className="footer__logo-img">
                <StaticImage alt={`Logo`} className='footer__logo' src='../images/Logo.png' /> 
              </div>
            </Link>

            <hr className='footer__lbr lbr'/>
            <div className="footer__media media">
              <a aria-label="facebook" href='https://www.facebook.com/profile.php?id=100011540748903' className='media__item'><Fb className='icon'/></a>
              <a aria-label="LinkedIn" href='https://www.linkedin.com/in/khanh-linh-le-91b48719a/' className='media__item'><Li className='icon'/></a>
              <a aria-label="Gmail" href='mailto:elearninglilian@gmail.com' className='media__item'><Gm className='icon'/></a>
            </div>
            <div className="btn btn-pink">Contact</div>
          </div>
          

        </footer>
    </div>
  )
}

export default Layout